import { IMWS,createWS,socketClose,detectConnectionStatus,createMaxAndMinSeq,GetMaxAndMinSeq } from './im';
import { MsgConfirm } from './msgeResend';
import { login , getUserInfo } from '@/api/index';
import { GetChatUserInfo } from '@/api/chat';
import store from '@/store/index';
import router from '../router';



var WS_URL = null;      // 通讯连接地址
var retry = null;       // 重连定时器
var retryCounter = 0;   // 当前重连计数器
var heartBeat = 0;      // 心跳检测连接计数
var heartTimer = null;  // 心跳检测连计时器
var route = {};         // URL数据


// 获取URL数据
if(location.href.indexOf('?') !== -1){
    route = getJsUrl(location.href);
}
// console.log(route.name);


async function onTestLogin(){
    var username = prompt("登录账号：");
    var password = prompt("登录密码：");
    store.commit('CHATLIST/onReset');
    await login({username,password}).then(res=>{
        console.log('登录成功',res);
        // 本地缓存设置
        if(localStorage.getItem('uid') !== res.data.id){store.commit('MESSAGE/onReset');}   // 消息缓存是否清空
        localStorage.setItem('uid',res.data.id);        // UID
        localStorage.setItem('token',res.data.token);   // TOKEN
        localStorage.setItem('PullMode','inbox');       // 拉取消息模式
        // 通讯初始化
        webSocketInit();
    });
    await getUserInfo().then(res=>{
        console.log('获取用户信息',res);
        localStorage.setItem('nickname',res.data.nickname);
    })
}
// onTestLogin();


async function simulationLogin(){
    if(location.href.indexOf('?') !== -1){
        route = getJsUrl(location.href);
    };
    if(IMWS){ return }; // 如果已经连接上了webstocket，直接阻断
    store.commit('CHATLIST/onReset');
    // await login({
    //     username:route.user,
    //     password:route.pass,
    //     nickname:route.nickname ? decodeURI(route.nickname) : '',
    //     user_id:localStorage.getItem('userID') ? localStorage.getItem('userID') : '',
    // }).then(res=>{
    //     console.log('登录成功',res);
    //     // 本地缓存设置
    //     if(localStorage.getItem('uid') !== res.data.id){store.commit('MESSAGE/onReset');}   // 消息缓存是否清空
    //     localStorage.setItem('uid',res.data.id);        // UID
    //     localStorage.setItem('token',res.data.token);   // TOKEN
    //     localStorage.setItem('PullMode','inbox');       // 拉取消息模式
    //     // localStorage.setItem('phase','start');          // 轮询接待
    //     // 通讯初始化
    //     webSocketInit();
    // });
    await login({
        username:route.account_id ? route.account_id :route.user,
        password:route.pass,
        nickname:route.nickname ? decodeURI(route.nickname) : '',
        user_id:localStorage.getItem('userID') ? localStorage.getItem('userID') : '',
        distinct_id:route.distinct_id ? route.distinct_id : '',
        app_key:route.app_key ? route.app_key : '',
        sign:route.sign ? route.sign : '',
    }).then(res=>{
        console.log('登录成功',res);
        // 本地缓存设置
        if(localStorage.getItem('uid') !== res.data.id){store.commit('MESSAGE/onReset');}   // 消息缓存是否清空
        localStorage.setItem('uid',res.data.id);        // UID
        localStorage.setItem('token',res.data.token);   // TOKEN
        localStorage.setItem('PullMode','inbox');       // 拉取消息模式
        // localStorage.setItem('phase','start');          // 轮询接待
        // 通讯初始化
        webSocketInit();
    });
    await getUserInfo().then(res=>{
        console.log('获取用户信息',res);
        localStorage.setItem('userinfo',JSON.stringify(res.data));
    })
}


// webSocket初始化
function webSocketInit(){
    if(IMWS){
        socketClose();
    }
    
    // 通讯连接地址
    WS_URL = `${process.env.VUE_APP_WSURL}/ws_user?token=${localStorage.getItem('token')}&uid=${localStorage.getItem('uid')}&platform_id=1&product_id=${localStorage.getItem('productID')}&country_code=${localStorage.getItem('locale-language')}`;   // time=${new Date().getTime()}
    // WS_URL = `ws://152.69.211.177:10000/ws_user?token=${localStorage.getItem('token')}&uid=${localStorage.getItem('uid')}&platform_id=1&product_id=${localStorage.getItem('productID')}&country_code=${localStorage.getItem('locale-language')}`;
    // WS_URL = `wss://msg-gateway.ads1ddcc.com/ws_user?token=${localStorage.getItem('token')}&uid=${localStorage.getItem('uid')}&platform_id=1&product_id=${localStorage.getItem('productID')}&country_code=${localStorage.getItem('locale-language')}`;

    // 建立连接
    createWS(WS_URL);

    // 连接、关闭、错误
    IMWS.onopen = function(e){
        console.log('通讯已连接',e);
        retryCounter = 0;
        store.commit('MESSAGE/onSetNetworkStatus',false);     // 断线状态告知
        store.commit('MESSAGE/onSetWebstocketStatus',true);     // webtocket连接状态告知
        // 心跳检查连接包
        heartTimer = setInterval(()=>{
            detectConnectionStatus();
            heartBeat++;
            if(heartBeat > 3){
                if(heartTimer){ clearInterval(heartTimer);heartTimer = null; }
                heartBeat = 0;
                RetryConnect();
            };
        },3000);
        getSeq();
        if(retry){ clearInterval(retry); retry = null; }
    };
    IMWS.onclose = function(e){
        console.log('通讯已关闭',e);
        store.commit('MESSAGE/onSetWebstocketStatus',false);    // webtocket连接状态告知
        if(!retry){ RetryConnect(); }
    };
    IMWS.onerror = function(e){
        console.log('通信错误',e);
        store.commit('MESSAGE/onSetWebstocketStatus',false);    // webtocket连接状态告知
        if(!retry){ RetryConnect(); }
    };


    


    // 消息归类
    IMWS.onmessage = function(e){
        const outerLayer = JSON.parse(e.data);                  // 外层消息
        const innerLayer = JSON.parse(JSON.parse(e.data).data); // 内层消息

        switch(outerLayer.identify){
            case 'ping':        // 心跳检测连接状态
                heartBeat = 0;
                console.log('心跳',heartBeat);
                break;
            case 'send_msg':    // 发送消息：消息确认发送 或 进入到消息重发
                MsgConfirm(outerLayer.msg_incr);
                console.log('消息确认收到');
                break;
            case 'push_msg':    // 接收到消息
                console.log('接收到消息:',JSON.parse(e.data),JSON.parse(JSON.parse(e.data).data));
                if(innerLayer.content_type === 201 || innerLayer.content_type === 202){
                    if(innerLayer.content_type === 202){
                        localStorage.setItem('phase','stop');
                    };
                    store.commit('MESSAGE/onGroupSystemMsg',[innerLayer]);
                    localStorage.setItem('groupMaxSeq',innerLayer.seq);
                    // 日志记录 ---------------------------------------------------------------------------------
                    let logs = {
                        time:new Date().toLocaleString(),
                        uid:localStorage.getItem('uid'),
                        messageType:innerLayer.content_type,
                        receptionId:localStorage.getItem('receptionId')
                    };
                    // console.log(logs);
                }else{
                    if(innerLayer.chat_type === 1){
                        localStorage.setItem('max-seq',innerLayer.seq);
                    }else if(innerLayer.chat_type === 2){
                        localStorage.setItem('groupMaxSeq',innerLayer.seq);
                    };
                    store.commit('MESSAGE/onPushMessage',[innerLayer]);
                }
                break;
            case 'pull_msg_by_max_seq':     // 拉取大于客户端最大SEQ的最新消息
                console.log('拉取大于客户端最大SEQ的最新消息:',JSON.parse(e.data),JSON.parse(JSON.parse(e.data).data));
                if(!innerLayer.msg_list || innerLayer.msg_list.length < 10){
                    localStorage.setItem('NeedPullNewMessage','false');
                }
                if(innerLayer.msg_list){
                    store.commit('MESSAGE/onNewMessage',innerLayer.msg_list);
                }
                break;
            case 'get_unread_msg_by_max_seq':     // 拉取小于客户端最大SEQ的未读消息
                console.log('拉取小于客户端最大SEQ的未读消息:',JSON.parse(e.data),JSON.parse(JSON.parse(e.data).data));
                if(!innerLayer.msg_list || innerLayer.msg_list.length < 10){
                    localStorage.setItem('NeedPullUnreadMessage','false');
                }
                if(innerLayer.msg_list){
                    store.commit('MESSAGE/onUnreadMessage',innerLayer.msg_list);
                }
                break;
            case 'get_max_and_min_seq':     // 获取最大SEQ和最小SEQ
                console.log('获取最大SEQ和最小SEQ',innerLayer);
                store.commit('MESSAGE/ongroupSeqAssignment',innerLayer.group_max_and_min_seq);
                localStorage.setItem('max-seq',innerLayer.max_seq);
                localStorage.setItem('min-seq',innerLayer.min_seq);
                break;
            case 'inbox_msg_list':      // 最近消息拉取
                console.log('收件箱的消息:',JSON.parse(e.data),JSON.parse(JSON.parse(e.data).data));
                if(!innerLayer.msg_list || innerLayer.msg_list.length < 10){
                    localStorage.setItem('PullMode','history');
                }
                if(innerLayer.msg_list){
                    store.commit('MESSAGE/onPullInboxMessage',innerLayer.msg_list);
                }
                break;
            case 'push_signal_msg':     // 群系统消息接收
                // console.log('群系统消息接收:',JSON.parse(e.data),JSON.parse(JSON.parse(e.data).data));
                if(innerLayer.content_type === 307){ localStorage.setItem('phase','stop') };
                if(innerLayer.content_type === 308){
                    let { name , query } = router.currentRoute.value;
                    if(name === 'chat' && query.chatType === '2' && query.groupType === '1'){
                        store.commit('MESSAGE/onSetEndPopupShow',true);
                    }
                };
                if(innerLayer.content_type === 312){ store.commit('MESSAGE/onCommentGrey',JSON.parse(innerLayer.content)) };
                store.commit('MESSAGE/onGroupSystemMsg',[innerLayer]);
                // 日志记录 ---------------------------------------------------------------------------------
                let logs = {
                    time:new Date().toLocaleString(),
                    uid:localStorage.getItem('uid'),
                    messageType:innerLayer.content_type,
                    receptionId:localStorage.getItem('receptionId')
                };
                // console.log(logs);
                break;
            case 'seat_reception_status':   // 接待轮询
                // console.log('接待轮询',innerLayer);
                localStorage.setItem('receptionId',innerLayer.reception_center.id);
                localStorage.setItem('phase',innerLayer.reception_center.phase);
                if(innerLayer.reception_center.phase === 4 || innerLayer.reception_center.phase === 5 || innerLayer.reception_center.phase === 6){
                    localStorage.setItem('phase','stop');
                }
                break;
        };
    };
};


// 重连机制
function RetryConnect(){
    retry = setInterval(()=>{
        retryCounter++;
        if(retryCounter >= 3){
            store.commit('MESSAGE/onSetNetworkStatus',true);     // 断线状态告知
        }
        if(heartTimer){ clearInterval(heartTimer);heartTimer = null; }
        webSocketInit();
        console.log('重连中...');
    },3000);
}

// URL地址解析
function getJsUrl(){
	var pos,str,para,parastr; 
	var array =[]
	str = location.href; 
	parastr = str.split("?")[1]; 
	var arr = parastr.split("&");
	for (var i=0;i<arr.length;i++){
        array[arr[i].split("=")[0]]=arr[i].split("=")[1];
    }
    return array;
}

// 获取最大最小SEQ
function getSeq(){
    // 没有最大或最小SEQ请求获取
    let maxSeq = localStorage.getItem('max-seq');
    let minSeq = localStorage.getItem('min-seq');
    if(maxSeq === null || minSeq === null){
        createMaxAndMinSeq().then(mesaage=>{
            GetMaxAndMinSeq(mesaage);
        })
    }
}

export {
    WS_URL,
    webSocketInit,
    simulationLogin,
    getJsUrl
}